import styled, { css } from "styled-components";
import PropTypes from "prop-types";
import React from "react";

const Wrapper = styled.div`
  display: inline-block;
  border: 1px solid gray;
  width: 52px;
  height: 52px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;

  &:hover {
    cursor: pointer;
    border: 1px solid #5c5a5a;
  }

  ${(props) => {
    if (props.isDisabled) {
      return css`
        border: 1px solid #b4b4b4;
        pointer-events: none;

        svg > path {
          stroke: #b4b4b4;
        }
      `;
    }
  }}
`;

const ArrowCircleRight = ({ isDisabled, className, ...rest }) => {
  return (
    <Wrapper
      isDisabled={isDisabled}
      className={className}
      role="button"
      {...rest}
    >
      <svg
        width="32px"
        height="32px"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M17 12L7 12M17 12L13 16M17 12L13 8"
          stroke="#000000"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    </Wrapper>
  );
};

ArrowCircleRight.defaultProps = {
  className: null,
  isDisabled: true,
};

ArrowCircleRight.propTypes = {
  className: PropTypes.string,
  isDisabled: PropTypes.bool,
};

export default ArrowCircleRight;
