import Img from "gatsby-image";
import styled from "styled-components";

export const Wrapper = styled.div`
  cursor: pointer;
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: 1fr minmax(25%, auto);
  grid-column-gap: 0px;
  grid-row-gap: 0px;
  height: 100%;

  @media (min-width: 769px) and (max-width: 1010px) {
    grid-template-rows: 1fr minmax(35%, auto);
  }
  @media (max-width: 768px) {
    grid-template-rows: 1fr minmax(50%, auto);
  }

  &:hover {
    border-radius: 5px;
  }
`;

export const Hero = styled(Img)`
  background-color: transparent;
  grid-area: 1 / 1 / 3 / 2;
  border-radius: 5px;

  picture {
    object-fit: cover;
  }
`;

export const Subtitle = styled.div`
  grid-area: 2 / 1 / 3 / 2;
  z-index: 1;
  background-color: ${(props) => props.color};
  padding: 21px 30px 20px 31px;
  font-size: 20px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #ffffff;
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
  display: flex;
  align-items: center;
  justify-content: flex-start;

  @media (max-width: 768px) {
    font-size: 14px;
    padding: 18px 13px 18px 14px;
  }
`;
