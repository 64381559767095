import { Hero, Subtitle, Wrapper } from "./project-card-component-styles";
import { Link } from "gatsby";
import PropTypes from "prop-types";
import React from "react";
import { useImage } from "@queries/use-image";

const ProjectCard = (props) => {
  const { getFluidImageName } = useImage();

  return (
    <Link to={props.slug} style={{ textDecoration: "none" }}>
      <Wrapper {...props}>
        <Hero
          fluid={getFluidImageName(props.heroImage || "class-with-students")}
          alt="Estudantes na sala de aula"
        />
        <Subtitle color={props.bgColor}>{props.subtitle}</Subtitle>
      </Wrapper>
    </Link>
  );
};

ProjectCard.propTypes = {
  bgColor: PropTypes.string,
  heroImage: PropTypes.string,
  slug: PropTypes.string,
  subtitle: PropTypes.string,
};

export default ProjectCard;
