import { Subtitle } from "@components/project-card/project-card-component-styles";
import styled from "styled-components";

export const Wrapper = styled.div`
  width: 100%;
  overflow: hidden;
`;

export const CustomSlide = styled.div`
  height: 400px;
  width: 350px;

  ${Subtitle} {
    font-size: 20px;

    @media (max-width: 768px) {
      padding: 29px 22px;
    }
  }

  @media (min-width: 769px) and (max-width: 1010px) {
    height: 320px;
    width: 300px;
  }

  @media (max-width: 768px) {
    height: 291px;
    width: 255px;
  }
`;

export const NavigationButtons = styled.div`
  display: flex;
  flex-direction: row;
  & > :last-child {
    margin-left: 25px;
  }

  @media (max-width: 768px) {
    display: none;
  }
`;

export const Header = styled.div`
  display: flex;
  flex-direction: row;
  margin-bottom: 60px;
  justify-content: space-between;

  @media (max-width: 768px) {
    flex-direction: column;
    margin-bottom: 84px;
  }
`;

export const Title = styled.h2`
  font-size: 30px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #060038;
`;

export const RigthSide = styled.div`
  display: flex;
  flex: 1;
  margin-left: 30px;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  max-width: 730px;

  @media (max-width: 768px) {
    max-width: unset;
    margin-left: unset;
    margin-top: 20px;
  }
`;

export const Message = styled.p`
  max-width: 210px;
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #060038;
`;
