import "swiper/swiper.scss";
import {
  CustomSlide,
  Header,
  Message,
  NavigationButtons,
  RigthSide,
  Title,
  Wrapper,
} from "./projects-carousel-component-styles";
import { Swiper, SwiperSlide } from "swiper/react";
import SwiperCore, { Navigation } from "swiper";
import ArrowCircleLeft from "@components/icons/arrow-circle-left";
import ArrowCircleRight from "@components/icons/arrow-circle-right";
import ProjectCard from "@components/project-card/project-card-component";
import PropTypes from "prop-types";
import React from "react";

import { useProjectCollection } from "@queries/use-project-collection";
// install Swiper modules
SwiperCore.use([Navigation]);

const SUBTITLE_BG_COLORS = [
  "rgb(183, 76, 43)",
  "rgb(216, 46, 74)",
  "rgb(0, 110, 144)",
];

const ProjectsCarousel = (props) => {
  const { getProjectsFrontmatter } = useProjectCollection();
  const [slideIndex, setSlideIndex] = React.useState(0);

  const projects = getProjectsFrontmatter().filter(
    (proj) => !props.excludeIds.includes(proj.id)
  );

  return (
    <Wrapper>
      <Header>
        <Title>{props.title}</Title>
        <RigthSide>
          <Message>{props.subtitle}</Message>
          <NavigationButtons>
            <ArrowCircleLeft
              className="swiper-button-prev"
              aria-label="previous slide"
              isDisabled={slideIndex === 0}
            />
            <ArrowCircleRight
              className="swiper-button-next"
              aria-label="next slide"
              isDisabled={slideIndex === projects.length - 3}
            />
          </NavigationButtons>
        </RigthSide>
      </Header>

      <Swiper
        spaceBetween={29}
        slidesPerView="auto"
        // freeMode={true}
        allowTouchMove={true}
        updateOnWindowResize={true}
        onSlideChange={(swiper) => {
          setSlideIndex(swiper.activeIndex);
        }}
        navigation={{
          nextEl: ".swiper-button-next",
          prevEl: ".swiper-button-prev",
        }}
      >
        {projects.map((project, index) => {
          return (
            <SwiperSlide
              key={project.id + index}
              style={{ width: "min-content" }}
            >
              <CustomSlide>
                <ProjectCard
                  bgColor={
                    SUBTITLE_BG_COLORS[index % SUBTITLE_BG_COLORS.length]
                  }
                  subtitle={project.title}
                  slug={project.slug}
                  heroImage={project.heroImage}
                />
              </CustomSlide>
            </SwiperSlide>
          );
        })}
      </Swiper>
    </Wrapper>
  );
};

ProjectsCarousel.defaultProps = {
  title: "",
  subtitle: "",
  excludeIds: [],
};

ProjectsCarousel.propTypes = {
  title: PropTypes.string,
  subtitle: PropTypes.string,
  excludeIds: PropTypes.arrayOf(PropTypes.string),
};

export default ProjectsCarousel;
